import axios from "axios";

const BASE_URL = "https://intervalhealth.great-site.net";

// authenticate user
export const loginUser = async (email, password) => {
  const data = {
    email: email,
    password: password,
  };

  try {
    const response = await axios.post(
      `${BASE_URL}/intervalweb_server/signup_login/login_user.php`,
      data
    );
    return response.data;
  } catch (error) {
    console.error("Error logging in:", error);
    throw error;
  }
};

// forgot password
export const forgotPassword = async (email) => {
  const data = { email: email };

  try {
    const response = await axios.post(
      `${BASE_URL}/intervalweb_server/signup_login/forgot_password.php`,
      data
    );
    return response.data;
  } catch (error) {
    console.error("Error in forgot password:", error);
    throw error;
  }
};

// signup user
export const signupUser = async (userDetails) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/intervalweb_server/signup_login/signup_user.php`,
      userDetails
    );
    return response.data;
  } catch (error) {
    console.error("Error signing up:", error);
    throw error;
  }
};

// change password
export const changePassword = async (userDetails) => {
  const data = userDetails;
  try {
    const response = await axios.post(
      `${BASE_URL}/intervalweb_server/signup_login/signup_user.php`,
      data
    );
    return response.data;
  } catch (error) {
    console.error("Error changing password:", error);
    throw error;
  }
};

// fetch profile info:
/** email,
    firstName,
    lastName,
    password,
    height,
    weight,
    bloodGroup,
    menopauseStage:, (add more params in futures)*/
export const getProfileInfo = async (userDetails) => {
  const data = userDetails;
  try {
    const response = await axios.post(
      `${BASE_URL}/intervalweb_server/profile/get_profile.php`,
      data
    );
    return response.data;
  } catch (error) {
    console.error("Error retrieving profile details:", error);
    throw error;
  }
};

// update profile
export const updateUserProfileInfo = async (userDetails) => {
  const data = userDetails;
  try {
    const response = await axios.post(
      `${BASE_URL}/intervalweb_server/profile/profile.php`,
      data
    );
    return response.data;
  } catch (error) {
    console.error("Error updating profile details:", error);
    throw error;
  }
};

// daily checkin quesitons
// fetch daily questions
export const get_daily_checkin = async () => {
  try {
    const response = await fetch(
      `${BASE_URL}/intervalweb_server/forms/daily_checkin_questions.php`
    );
    return response;
  } catch (error) {
    console.error("Error retrieving daily checkin questions:", error);
    throw error;
  }
};

// submit daily questions
export const submit_daily_checkin = async (userDetails) => {
  const data = userDetails;
  try {
    const response = await axios.post(
      `${BASE_URL}/intervalweb_server/forms/daily_checkin_submit.php`,
      data
    );
    return response.data;
  } catch (error) {
    console.error("Error submitting daily checkin questions:", error);
    throw error;
  }
};

//verify daily checkin completion. See if the daily checkin is completed or not.
export const verify_daily_checkin = async (mrn) => {
  const data = {
    mrn: mrn,
  };
  try {
    const response = await axios.post(
      `${BASE_URL}/intervalweb_server/forms/daily_checkin_completed.php`,
      data
    );
    return response.data;
  } catch (error) {
    console.error("Error verifying daily checkin questions:", error);
    throw error;
  }
};
