import React, { useEffect, useState } from "react";
import axios from "axios";
import "../../styles/containers/Doctor.css";
import { useNavigate } from "react-router-dom";
import { Layout1 } from "../../components";

const Doctor = () => {
  const [doctors, setDoctors] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const doctorsPerPage = 3;
  const navigate = useNavigate();

  useEffect(() => {
    const fetchDoctors = async () => {
      try {
        const response = await axios.get(
          "https://intervalhealth.great-site.net/intervalweb_server/doctors/get_doctors.php"
        );
        if (Array.isArray(response.data)) {
          setDoctors(response.data);
        } else {
          console.error("Invalid data format");
        }
      } catch (error) {
        console.error("Error fetching doctor data:", error);
      }
    };

    fetchDoctors();
  }, []);

  const indexOfLastDoctor = currentPage * doctorsPerPage;
  const indexOfFirstDoctor = indexOfLastDoctor - doctorsPerPage;
  const currentDoctors = doctors.slice(indexOfFirstDoctor, indexOfLastDoctor);

  const nextPage = () => {
    if (indexOfLastDoctor < doctors.length) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const defaultDoctorImage =
    "https://via.placeholder.com/150/CCCCCC/000000?text=Doctor's+Image";
  const handleImageError = (event) => {
    event.target.src = defaultDoctorImage;
  };

  return (
    <>
      <Layout1>
        <div className="doctor-cards-container">
          {currentDoctors.map((doctor) => (
            <div
              key={doctor.id}
              className="doctor-card"
              onClick={() => navigate(doctor.website_link)}
              role="link"
              tabIndex="0"
            >
              <img
                src={doctor.doctor_photo_link}
                alt={doctor.doctor_name}
                onError={handleImageError}
                className="doctor-photo"
              />
              <div className="doctor-info">
                <h3>{doctor.doctor_name}</h3>
                <p>{doctor.doctor_type}</p>
                {/* <p>{doctor.description}</p> */}
              </div>
            </div>
          ))}
          <div className="pagination">
            {currentPage > 1 && <button onClick={prevPage}>Previous</button>}
            {indexOfLastDoctor < doctors.length && (
              <button onClick={nextPage}>Next</button>
            )}
          </div>
        </div>
      </Layout1>
    </>
  );
};

export default Doctor;
