// src/components/LoginSignup.js
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../styles/containers/LoginSignup.css";
import {
  loginUser,
  forgotPassword,
  signupUser,
} from "../../components/api_calls/Api";
import { interval_text, butterfly } from "../../assests";

function LoginSignup() {
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLogin, setIsLogin] = useState(true);
  const [forgotPasswordState, setForgotPasswordState] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [displayError, setDisplayError] = useState("");

  const navigate = useNavigate();

  const handleLoginSignupToggle = () => {
    setIsLogin(!isLogin);
    setForgotPasswordState(false);
  };

  const handleForgotPassword = () => {
    setForgotPasswordState(!forgotPasswordState);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (forgotPasswordState) {
        if (!email) {
          console.warn("Please fill in all fields.");
          return;
        }

        const response = await forgotPassword(email);
        console.log("Response:", response);
        if (response.message === "User exists") {
          console.log("Sending a password reset link.");
        }
      } else if (isLogin) {
        if (!email || !password) {
          console.warn("Please fill in all fields.");
          return;
        }

        const response = await loginUser(email, password);
        console.log("Response:", response);

        if (response.message === "Login successful") {
          localStorage.setItem("authToken", response.token);
          localStorage.setItem("mrn", response.mrn);
          localStorage.setItem("password", password);
          localStorage.setItem("given_name", response.given_name);
          localStorage.setItem("sur_name", response.sur_name);
          localStorage.setItem("email", response.email);
          navigate("/");
        } else if (response.message === "Invalid email or password") {
          setDisplayError(response.message);
        }
      } else {
        if (!firstname || !lastname || !email || !password) {
          console.warn("Please fill in all fields.");
          return;
        }

        const userDetails = {
          given_name: firstname,
          sur_name: lastname,
          email: email,
          password: password,
        };

        const response = await signupUser(userDetails);
        console.log("Response:", response);
        setIsLogin(true);
        if (response.message === "User already exists.") {
          setDisplayError(response.message);
        }
      }
    } catch (error) {
      console.error("Error:", error);
      setDisplayError("An error occurred. Please try again later.");
    }
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible((prevState) => !prevState);
  };

  return (
    <div className="login_signup">
      <div className="interval_banner">
        <img src={butterfly} alt="butterfly" className="butterfly" />
        <div className="interval_text">
          <img src={interval_text} alt="Interval Text" />
        </div>
      </div>
      <div className="form_box">
        <form onSubmit={handleSubmit}>
          <div style={{ color: "pink" }}>{displayError}</div>
          {!isLogin && (
            <>
              <div className="form-group">
                <input
                  type="text"
                  value={firstname}
                  id="FirstName"
                  onChange={(e) => setFirstname(e.target.value)}
                  required
                />
                <label htmlFor="FirstName">First Name</label>
              </div>
              <div className="form-group">
                <input
                  type="text"
                  value={lastname}
                  id="LastName"
                  onChange={(e) => setLastname(e.target.value)}
                  required
                />
                <label htmlFor="LastName">Last Name</label>
              </div>
            </>
          )}
          <div className="form-group">
            <input
              type="text"
              value={email}
              id="Email"
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <label htmlFor="Email">Email</label>
          </div>
          {!forgotPasswordState && (
            <div className="form-group" style={{ position: "relative" }}>
              <input
                type={passwordVisible ? "text" : "password"}
                value={password}
                id="Password"
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <label htmlFor="Password">Password</label>
              <FontAwesomeIcon
                icon={passwordVisible ? faEyeSlash : faEye}
                onClick={togglePasswordVisibility}
                style={{
                  position: "absolute",
                  right: "5px",
                  top: "50%",
                  transform: "translateY(-50%)",
                  cursor: "pointer",
                  color: "black",
                }}
              />
            </div>
          )}
          <button type="submit">
            {forgotPasswordState ? "Next" : isLogin ? "SIGN-IN" : "REGISTER"}
          </button>
        </form>
        <p>
          {isLogin ? "Don't have an account? " : "Already have an account? "}
          <button onClick={handleLoginSignupToggle}>
            {isLogin ? "Sign Up" : "Login"}
          </button>
        </p>
        <p className="forgot_password">
          {forgotPasswordState ? (
            <button onClick={handleForgotPassword}>Back</button>
          ) : isLogin ? (
            <button onClick={handleForgotPassword}>Forgot password</button>
          ) : null}
        </p>
      </div>
    </div>
  );
}

export default LoginSignup;
