import React, { useState, useEffect } from "react"; // Corrected import
// layout components
import { Navbar, UserAvatar } from "../";
import { BackButton } from "../reusables/Buttons";
import { pink_flower, red_flower, butterfly } from "../../assests";

import "../../styles/components/Layout1.css";

// layout page: use this page to display other important components of the app as the child.
const Layout1 = ({ children, displayBackButton }) => {
  const [user_firstname, set_user_firstname] = useState("");

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        set_user_firstname(localStorage.getItem("given_name"));
      } catch (error) {
        console.error("There was an error fetching the data!", error);
      }
    };
    fetchUserData();
  }, [user_firstname]);

  // the page contains 3 layer with different z-indexes.
  // layer 1 is the lowest layer and layer 3 is the topmost layer.
  return (
    <div className="layout-1">
      <main className="layer-2">{children}</main>

      <div className="layer-3">
        <div className="top_bar">
          <img src={red_flower} alt="" className="red_flower" />
          {displayBackButton ? (
            <div className="back_button-container">
              <BackButton size={40} color="var(--color-green)" />
            </div>
          ) : (
            <></>
          )}

          <img src={butterfly} alt="" className="butterfly" />
          <div className="user_avatar_position">
            <UserAvatar size={48} />
          </div>
          <p className="welcome_user">Hi, {user_firstname}</p>
        </div>
        <img src={pink_flower} alt="" className="pink_flower" />
        <div className="navbar-container">
          <Navbar />
        </div>
      </div>
    </div>
  );
};

export default Layout1;
