import "../../styles/containers/DailyCheckin.css";
import {
  Navbar,
  UserAvatar,
  PinkButton,
  CustomRadioButton,
  Layout2,
} from "../../components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useState } from "react";
import {
  get_daily_checkin,
  submit_daily_checkin,
  verify_daily_checkin,
} from "../../components/api_calls/Api";
import ProgressBar from "../../components/progress_bar/ProgressBar";

const abbreviatedWeekdays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
const abbreviatedMonth = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "June",
  "July",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];

function getDate() {
  const today = new Date();
  const month = today.getMonth() + 1;
  const year = today.getFullYear();
  const date = today.getDate();
  const day = today.getDay();
  return `${abbreviatedMonth[month - 1]} ${date}, ${year} (${
    abbreviatedWeekdays[day]
  })`;
}

const CheckInSection = ({
  param,
  description,
  values,
  onChange,
  className,
}) => (
  <div className={`check-in-section ${className}`}>
    <div className="left">
      <h5>{param}</h5>
      <p>{description}</p>
    </div>
    <div className="right">
      {values.map(({ id, option_text }) => (
        <label key={id}>
          <span>{option_text}</span>
          <CustomRadioButton
            type="radio"
            name={param}
            value={id}
            onChange={() => onChange(id)}
          />
        </label>
      ))}
    </div>
  </div>
);

const DailyCheckin = () => {
  const date = getDate();
  const [checkInParams, setCheckInParams] = useState([]);
  const [responses, setResponses] = useState({});
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [transitioning, setTransitioning] = useState(false);
  const [checkin_completed, set_checkin_completed] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await get_daily_checkin();
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        if (Array.isArray(data)) {
          setCheckInParams(data);
        } else {
          console.error("Fetched data is not an array:", data);
        }
      } catch (error) {
        console.error("Error fetching questions:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const handle_checkin_completed = async () => {
      const mrn = localStorage.getItem("mrn");
      const response = await verify_daily_checkin(mrn);
      if (response && response.status === "completed") {
        console.log(response);
        set_checkin_completed(true);
      }
    };
    handle_checkin_completed();
  }, []);

  const handleSelectionChange = (question_id, option_id) => {
    setResponses((prevResponses) => ({
      ...prevResponses,
      [question_id]: option_id,
    }));

    if (currentQuestionIndex < checkInParams.length - 1) {
      setTransitioning(true);
      setTimeout(() => {
        setTransitioning(false);
        setCurrentQuestionIndex(currentQuestionIndex + 1);
      }, 500); // Delay of 0.5 seconds
    } else {
      // Move to the submission stage
      setCurrentQuestionIndex(checkInParams.length); // Set to length to show submit button
    }
  };

  const handleSubmit = async () => {
    const data = Object.entries(responses).map(([question_id, option_id]) => ({
      user_id: localStorage.getItem("mrn"),
      question_id: question_id,
      option_id: option_id,
    }));
    try {
      const responseData = await submit_daily_checkin(data);
      if (responseData.message === "Record inserted successfully.") {
        console.log(responseData.message);
      } else {
        console.error("Invalid response data:", responseData);
      }
    } catch (error) {
      console.error("Error submitting daily check-in question:", error);
    }
  };

  const progress = ((currentQuestionIndex + 1) / checkInParams.length) * 100;

  return (
    <Layout2 showBackButton={true}>
      <div className="daily_checkin">
        <h2 className="heading">
          Daily Wellness <br />
          Questions
        </h2>
        <div className="subtext">
          <FontAwesomeIcon icon={faCalendarAlt} size="1x" /> {date}
        </div>
        {!checkin_completed ? (
          <section className="checkin_questions">
            <div className="progress-bar-container">
              <ProgressBar progress={progress} />
            </div>
            {checkInParams.length > 0 ? (
              currentQuestionIndex < checkInParams.length ? (
                <CheckInSection
                  key={checkInParams[currentQuestionIndex].id}
                  param={checkInParams[currentQuestionIndex].question_text}
                  description={checkInParams[currentQuestionIndex].description}
                  values={checkInParams[currentQuestionIndex].options}
                  onChange={(option_id) =>
                    handleSelectionChange(
                      checkInParams[currentQuestionIndex].id,
                      option_id
                    )
                  }
                  className={transitioning ? "fade-out" : "fade-in"}
                />
              ) : (
                <div className="submit">
                  <PinkButton onClick={handleSubmit}>Submit</PinkButton>
                </div>
              )
            ) : (
              <p>Loading questions...</p>
            )}
          </section>
        ) : (
          <div className="checkin_not_completed">
            <p>
              You have completed your daily check-in yet. Please come back
              tommorow.
            </p>
          </div>
        )}
      </div>
    </Layout2>
  );
};

export default DailyCheckin;
