import React from "react";
import { Link } from "react-router-dom";
import {
  FaHome,
  FaMapMarkerAlt,
  FaUserMd,
  FaBlog,
  FaPlus,
} from "react-icons/fa";
import "../../styles/components/Navbar.css";

const Navbar = () => {
  return (
    <nav className="navbar">
      <ul>
        <li>
          <Link to="/" className="navbar-link">
            <FaHome />
            <span>Home</span>
          </Link>
        </li>
        <li>
          <Link to="/insights" className="navbar-link">
            <FaMapMarkerAlt />
            <span>Insights</span>
          </Link>
        </li>
        <li className="">
          <Link to="/daily_checkin" className="navbar-link add">
            <FaPlus size={25} />
          </Link>
        </li>
        <li>
          <Link to="/doctor" className="navbar-link">
            <FaUserMd />
            <span>Doctor</span>
          </Link>
        </li>
        <li>
          <Link to="/blogs" className="navbar-link">
            <FaBlog />
            <span>Blogs</span>
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
