import "../../styles/components/Carousal.css";
import { useState } from "react";
import Slider from "react-slick";
import { analytics, doctor, lifestyle, painrelief } from "../../assests/";

// carousal
const images = [
  { name: "Insights", address: analytics, link: "/analytics" },
  { name: "Doctor", address: doctor, link: "/doctor" },
  { name: "Lifestyle", address: lifestyle, link: "/analytics" },
  { name: "Painrelief", address: painrelief, link: "/relief" },
];

function Carousal() {
  const [imageIndex, setImageIndex] = useState(0);

  const settings = {
    className: "slider",
    centerMode: true,
    infinite: true,
    centerPadding: "0px",
    slidesToShow: 2.325,
    speed: 500,
    arrows: false,
    beforeChange: (current, next) => setImageIndex(next),
  };

  return (
    <>
      <Slider {...settings}>
        {images.map((img, idx) => (
          <div
            key={idx}
            className={
              idx === imageIndex ? "slide activeSlide" : "slide shadow"
            }
          >
            <a
              href={img.link}
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <img src={img.address} alt={`image-${img.name}`} />
              <div className="blur-effect"></div>
              <p>{img.name}</p>
            </a>
          </div>
        ))}
      </Slider>
    </>
  );
}

export default Carousal;
