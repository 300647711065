import React from "react";
import { useNavigate } from "react-router-dom";
import "../../styles/containers/Home.css";
import { PinkButton, Carousal, Layout1 } from "../../components";

const Home = () => {
  const navigate = useNavigate();
  const navigateCheckIn = () => {
    navigate("/daily_checkin");
  };

  return (
    <>
      <Layout1 displayBackButton={false}>
        <div className="home">
          {/* insight */}
          <div className="insight-container">
            <h1 style={{ color: "white" }}>Insights</h1>
          </div>
          <div className="quick_check_in">
            <p>
              How are you feeling <br />
              today?
            </p>
            <div className="quick_check_in-button">
              <PinkButton onClick={navigateCheckIn}>CHECK-IN</PinkButton>
            </div>
          </div>
          <div className="carousal-container">
            <Carousal />
          </div>
        </div>
      </Layout1>
    </>
  );
};

export default Home;
