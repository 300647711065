import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FaUserCircle } from "react-icons/fa";
import "../../styles/components/UserAvatar.css"; // Add your CSS styles for the user profile component

function UserAvatar({ size }) {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const toggleDropdown = () => {
    console.log("user avatar clicked");
    setIsOpen(!isOpen);
    console.log("drop down: ", isOpen);
  };

  const handleProfileClick = () => {
    // Handle profile click action
    console.log("Profile clicked");
    setIsOpen(false); // Close the dropdown after action
    navigate("/profile");
  };

  const handleSettingsClick = () => {
    // Handle settings click action 
    console.log("Settings clicked");
    setIsOpen(false); // Close the dropdown after action
    navigate("/settings");
  };

  const handleLogoutClick = () => {
    // Handle logout click action
    console.log("Logout clicked");
    localStorage.clear(); // clear local storage info
    navigate("/login-signup"); // Redirect to the login page
    setIsOpen(false); // Close the dropdown after action
  };

  return (
    <div className="user-profile">
      <div className="profile-icon" onClick={toggleDropdown}>
        <FaUserCircle size={size} />
      </div>
      {isOpen && (
        <div className="dropdown-menu" id="dropdown-menu">
          <ul>
            <li onClick={handleProfileClick}>Profile</li>
            <li onClick={handleSettingsClick}>Settings</li>
            <li onClick={handleLogoutClick}>Logout</li>
          </ul>
        </div>
      )}
    </div>
  );
}

export default UserAvatar;
