import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import "../../styles/containers/Welcome.css"; // Import the CSS file
import { interval_text } from "../../assests";
import { useNavigate } from "react-router-dom";

const StartPage = ({ onPageChange }) => {
  const navigate = useNavigate();
  return (
    <motion.div
      className="start-page"
      initial={{ opacity: 0, scale: 0.75 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 1 }}
      transition={{ duration: transitionDuration }}
    >
      <h1>
        LET'S <br /> GET STARTED
      </h1>
      <p>Get your personalized healthcare...</p>
      <button
        onClick={() => {
          navigate("/user-agreement");
        }}
      >
        Join Now
      </button>
    </motion.div>
  );
};

const LogoPage = ({ onPageChange }) => {
  return (
    <motion.div
      className="logo-page"
      initial={{ opacity: 0, scale: 0.75 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0.5, scale: 1 }}
      transition={{ duration: transitionDuration }}
    >
      <img src={interval_text} alt="Interval Logo" className="logo" />
    </motion.div>
  );
};

const transitionDuration = 1; // Transition after x seconds
const transitionAfter = 2.5;

const Hero = () => {
  const [currentPage, setCurrentPage] = useState("logo");

  useEffect(() => {
    // Set a timeout to change currentPage after 2.5 seconds
    const timeoutId = setTimeout(() => {
      setCurrentPage("start");
    }, transitionAfter * 1000); // setTimeout expects milliseconds, so multiply seconds by 1000

    // Clean up the timeout to avoid memory leaks
    return () => clearTimeout(timeoutId);
  }, []); // Empty dependency array ensures this effect runs only once after initial render

  return (
    <div className="hero-container">
      <AnimatePresence initial={false} mode="wait">
        {currentPage === "logo" && (
          <LogoPage key="logo" onPageChange={setCurrentPage} />
        )}
        {currentPage === "start" && <StartPage key="start" />}
      </AnimatePresence>
    </div>
  );
};
export default Hero;
