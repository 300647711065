import React, { useState, useEffect } from "react"; // Corrected import
// layout components
import { Navbar, UserAvatar } from "../";
import { BackButton } from "../reusables/Buttons";
import { pink_flower, red_flower, butterfly } from "../../assests";

import "../../styles/components/Layout2.css";

// layout page: use this page to display other important components of the app as the child.
const Layout2 = ({ children, showBackButton = false }) => {
  // pass in child components.
  // header: provide a title for the page.

  return (
    <div className="layout-2">
      {/* background  */}
      <div className="layer-1">
        <img src={red_flower} alt="" className="red_flower" />
        <img src={butterfly} alt="" className="butterfly" />
        <img src={pink_flower} alt="" className="pink_flower" />
      </div>

      {/* heading and date & backbutton */}
      <div className="layer-3">
        {showBackButton ? <BackButton color="white" /> : <></>}
      </div>

      {/* child elems */}
      <main className="layer-2">{children}</main>
    </div>
  );
};

export default Layout2;
