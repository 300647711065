import React from "react";
import "../../styles/components/Buttons.css";
import { useNavigate } from "react-router-dom";
import { FaAngleLeft } from "react-icons/fa";

const PinkButton = ({ onClick, children }) => {
  return (
    <button className="pink-button" onClick={onClick}>
      {children}
    </button>
  );
};

const CustomRadioButton = ({ name, value, checked, onChange }) => {
  return (
    <input
      type="radio"
      name={name}
      value={value}
      checked={checked}
      onChange={onChange}
      className="radio-button"
    />
  );
};

const BackButton = ({ size = 40, color = "var(--color-purple)" }) => {
  const navigate = useNavigate();
  return (
    <div className="profile_back-button" onClick={() => navigate(-1)}>
      <FaAngleLeft size={size} color={color} />
    </div>
  );
};

export { PinkButton, CustomRadioButton, BackButton };
