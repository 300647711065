import React, { useState, useEffect } from "react";
import { BackButton } from "../../components/reusables/Buttons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faUserCircle,
  faEye,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";
import {
  getProfileInfo,
  updateUserProfileInfo,
} from "../../components/api_calls/Api";
import { red_flower, butterfly } from "../../assests";
import { useNavigate } from "react-router-dom";
import "../../styles/containers/Profile.css";

const Layout3 = () => {
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const [formData, setFormData] = useState({
    email: localStorage.getItem("email"),
    firstName: localStorage.getItem("given_name"),
    lastName: localStorage.getItem("sur_name"),
    password: localStorage.getItem("password"),
    height: "",
    weight: "",
    bloodGroup: "",
    menopauseStage: "",
  });

  const toggleVisibility = () => setVisible(!visible);

  useEffect(() => {
    const fetchProfileInfo = async () => {
      try {
        const response = await getProfileInfo({
          mrn: localStorage.getItem("mrn"),
        });
        if (response) {
          setFormData((prevFormData) => ({
            ...prevFormData,
            firstName:
              response.given_name || localStorage.getItem("given_name"),
            lastName: response.sur_name || localStorage.getItem("sur_name"),
            email: response.email || localStorage.getItem("email"),
            dob: response.DOB || "",
            height: response.height || "",
            weight: response.weight || "",
            bloodGroup: response.bloodGroup || "",
            menopauseStage: response.menopauseStage || "",
          }));
        } else {
          console.error("Invalid response data:", response);
        }
      } catch (error) {
        console.error("Error fetching profile information:", error);
      }
    };

    fetchProfileInfo();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (Object.values(formData).some((field) => field === "")) {
      console.warn("Please fill all the fields.");
    } else {
      try {
        const data = {
          mrn: localStorage.getItem("mrn"),
          dob: formData.dob,
          height: formData.height,
          weight: formData.weight,
          bloodGroup: formData.bloodGroup,
          menopauseStage: formData.menopauseStage,
        };
        const response = await updateUserProfileInfo(data);

        if (response.message === "User profile updated.") {
          navigate("/");
        } else {
          console.error("Error updating profile:", response.message);
        }
      } catch (error) {
        console.error("Error updating profile:", error.message);
      }
    }
  };

  return (
    <div className="profile-page-container">
      <div className="profile-layer-1">
        <BackButton color="white" />
        <div className="user-profile-container">
          <FontAwesomeIcon icon={faUserCircle} className="user-image" />
          <button>
            <FontAwesomeIcon icon={faEdit} />
          </button>
          <h3>{`${formData.firstName} ${formData.lastName}`}</h3>
        </div>
      </div>

      <div className="profile-layer-2">
        <img src={red_flower} alt="Red flower" className="red_flower" />
        <img src={butterfly} alt="Butterfly" className="butterfly" />
      </div>

      <div className="profile-layer-3">
        <form onSubmit={handleSubmit} className="profile_form">
          <div className="basic-info">
            <h2>Basic Information</h2>
            <label>Email:</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Enter your email"
            />
            <label>Password:</label>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                position: "relative",
              }}
            >
              <input
                type={visible ? "text" : "password"}
                name="password"
                value={formData.password}
                onChange={handleChange}
                placeholder="Enter your password"
              />
              <FontAwesomeIcon
                icon={visible ? faEyeSlash : faEye}
                onClick={toggleVisibility}
                style={{
                  position: "absolute",
                  right: "10px",
                  top: "13px",
                  cursor: "pointer",
                }}
              />
            </div>
          </div>

          <div className="personal-info">
            <h2>Personal Information</h2>
            <label>Date of Birth:</label>
            <input
              type="date"
              name="dob"
              value={formData.dob}
              onChange={handleChange}
            />
            <label>Height (cm):</label>
            <input
              type="number"
              name="height"
              value={formData.height}
              onChange={handleChange}
              placeholder="Enter your height"
            />
            <label>Weight (kg):</label>
            <input
              type="number"
              name="weight"
              value={formData.weight}
              onChange={handleChange}
              placeholder="Enter your weight"
            />
            <label>Blood Group:</label>
            <select
              name="bloodGroup"
              value={formData.bloodGroup}
              onChange={handleChange}
            >
              <option value="" disabled>
                {formData.bloodGroup || "Select your blood group"}
              </option>
              <option value="A+">A+</option>
              <option value="A-">A-</option>
              <option value="B+">B+</option>
              <option value="B-">B-</option>
              <option value="AB+">AB+</option>
              <option value="AB-">AB-</option>
              <option value="O+">O+</option>
              <option value="O-">O-</option>
            </select>
            <label>Menopause Stage:</label>
            <select
              name="menopauseStage"
              value={formData.menopauseStage}
              onChange={handleChange}
            >
              <option value="" disabled>
                {formData.menopauseStage || "Select your menopause stage"}
              </option>
              <option value="Pre">Pre</option>
              <option value="Peri">Peri</option>
              <option value="Post">Post</option>
            </select>
          </div>
          <button type="submit">Save</button>
        </form>
      </div>
    </div>
  );
};

export default Layout3;
