import React, { useEffect, useState } from "react";
import {
  FaAngleRight,
  FaUser,
  FaFileContract,
  FaBell,
  FaInfoCircle,
  FaShieldAlt,
} from "react-icons/fa";
import { BackButton } from "../../components/reusables/Buttons";
import { useNavigate } from "react-router-dom";
import "../../styles/containers/Settings.css";

const Profile = () => {
  return (
    <>
      <h1>Profile</h1>
    </>
  );
};

const UserAgreement = () => {
  return (
    <>
      <h1>UserAgreement</h1>
    </>
  );
};

const Notifications = () => {
  return (
    <>
      <h1></h1>
    </>
  );
};

const About = () => {
  return (
    <>
      <h1></h1>
    </>
  );
};

const Privacy = () => {
  return (
    <>
      <h1></h1>
    </>
  );
};

const options = [
  { name: "Profile", icon: <FaUser />, href: "/profile" },
  { name: "User Agreement", icon: <FaFileContract />, href: "/user-agreement" },
  { name: "Notifications", icon: <FaBell />, href: "/" },
  { name: "About", icon: <FaInfoCircle />, href: "/" },
  { name: "Privacy", icon: <FaShieldAlt />, href: "/" },
];

const Settings = () => {
  const navigate = useNavigate();
  return (
    <div className="settings">
      <div className="top-bar"></div>
      <div className="profile_back-button">
        <BackButton size={40} />
      </div>
      <h1>Settings</h1>
      <ul className="list">
        {options.map((option, index) => (
          <a
            href={option.href}
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <li key={index}>
              <p className="icon">{option.icon}</p>{" "}
              <p className="name">{option.name}</p>{" "}
              <p className="right-angle-arrow">
                <FaAngleRight />
              </p>
            </li>
          </a>
        ))}
      </ul>
      <button type="button" className="settings-logout">
        Logout
      </button>
    </div>
  );
};

export default Settings;
