import React, { useState } from "react";
import "../../styles/containers/UserAgreement.css";
import { useNavigate } from "react-router-dom";

const UserAgreement = () => {
  const navigate = useNavigate();

  const [isAgreed, setIsAgreed] = useState(false);

  const handleScroll = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    setIsAgreed(bottom);
  };

  const handleAccept = () => {
    alert("Agreement Accepted!");
    // Implement the accept logic here
    navigate("/login-signup");
  };

  const handleDecline = () => {
    alert("Agreement Declined.");
    // Implement the decline logic here
  };

  return (
    <div className="outer_container_agreement">
      <div className="agreement-container">
        <h2>User Agreement</h2>
        <div className="agreement-box" onScroll={handleScroll}>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec a
            diam lectus. Sed sit amet ipsum mauris. Maecenas congue ligula ac
            quam viverra nec consectetur ante hendrerit. Donec et mollis dolor.
            Praesent et diam eget libero egestas mattis sit amet vitae augue.
            Nam tincidunt congue enim, ut porta lorem lacinia consectetur. Donec
            ut libero sed arcu vehicula ultricies a non tortor. Lorem ipsum
            dolor sit amet, consectetur adipiscing elit. Aenean ut gravida
            lorem. Ut turpis felis, pulvinar a semper sed, adipiscing id dolor.
            Pellentesque auctor nisi id magna consequat sagittis. Curabitur
            dapibus enim sit amet elit pharetra tincidunt feugiat nisl
            imperdiet. Ut convallis libero in urna ultrices accumsan. Donec sed
            odio eros. Donec viverra mi quis quam pulvinar at malesuada arcu
            rhoncus. Cum sociis natoque penatibus et magnis dis parturient
            montes, nascetur ridiculus mus. In rutrum accumsan ultricies. Mauris
            vitae nisi at sem facilisis semper ac in est.
            {/* Repeat content or add more content here to make it scrollable */}
          </p>
        </div>
        <div className="button-container">
          <button className="decline-button" onClick={handleDecline}>
            Decline
          </button>
          <button
            className="accept-button"
            onClick={handleAccept}
            disabled={!isAgreed}
          >
            Accept
          </button>
        </div>
      </div>
    </div>
  );
};

export default UserAgreement;
