import React from "react";
import { Outlet, Navigate } from "react-router-dom";

// protected router is used to verify if the page needs to be verified.
    // if user tries to access a rotected router, he'l be redirected to login-signup page.
const ProtectedRoute = () => {
  const authToken = localStorage.getItem("authToken");
  return authToken ? <Outlet /> : <Navigate to="/login-signup" />;
};

export default ProtectedRoute;
