import "./styles/App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import {
  Welcome,
  Home,
  DailyCheckin,
  LoginSignup,
  Profile,
  Doctor,
  UserAgreement,
  Settings,
} from "./containers/";
import { ProtectedRoute, Carousal, Layout1, Layout2 } from "./components";
import Question from "./containers/checkin/widget/Question.jsx";

function App() {
  localStorage.setItem("firstVisit", "true");
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/layout-1" element={<Layout1 />} />
          <Route path="/layout-2" element={<Layout2 />} />
          <Route path="/welcome" element={<Welcome />} />
          <Route path="/login-signup" element={<LoginSignup />} />
          <Route path="/user-agreement" element={<UserAgreement />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/carousal" element={<Carousal />} />
          {/* <Route path="/question" element={<Question />} /> */}
          <Route element={<ProtectedRoute />}>
            <Route element={<Home />} path="/" exact />
            <Route element={<DailyCheckin />} path="/daily_checkin" />
            <Route element={<Profile />} path="/profile" />
            <Route element={<Doctor />} path="/doctor" />
          </Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
